<template>
  <div class="collabos-body">
    <Navigator />
    <div class="collabos-c">
      <div class="header-c-c"></div>
      <div class="mosaic-c">
        <div class="collabo-item" v-for="(c, i) in data" :key="i">
          <img :src="srcImg(c.image)" alt="image" class="collabo-img" />
          <p class="t">{{ c.name }}</p>
          <div class="dflx fdc aic sm-c">
            <a
              :href="c.facebook"
              target="_blank"
              rel="noopener noreferrer"
              class="sm fb"
              v-if="c.facebook !== ''"
              ><span>FB/</span>{{ fbSM(c.facebook) }}</a
            ><!--FACEBOOK-->
            <a
              :href="c.twiter"
              target="_blank"
              rel="noopener noreferrer"
              class="sm tw"
              v-if="c.twiter !== ''"
              ><span>@</span>{{ querySM(c.twiter) }}</a
            ><!--TWITTER-->
            <a
              :href="c.instagram"
              target="_blank"
              rel="noopener noreferrer"
              class="sm ig"
              v-if="c.instagram !== ''"
              ><span>@</span>{{ querySM(c.instagram) }}</a
            ><!--INSTAGRAM-->
            <a
              :href="c.linkedin"
              target="_blank"
              rel="noopener noreferrer"
              class="sm li"
              v-if="c.linkedin !== ''"
              ><span>LN/</span>{{ c.linkedin }}</a
            ><!--LINKEDIN-->
            <a
              :href="'mailto:' + c.email"
              target="_blank"
              rel="noopener noreferrer"
              class="sm em"
              v-if="c.email !== ''"
              >{{ c.email }}</a
            ><!--EMAIL-->
          </div>
          <div class="desc" v-html="c.about"></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";
import Footer from "../components/items-page/Footer";
let urlpath = require("../global/url");

export default {
  components: {
    Navigator,
    Footer,
  },
  data() {
    return {
      urlpath,
    };
  },
  created() {
    this.getAllInfoClb("colaboradores");
  },
  computed: {
    data() {
      const colaboladoresFlter = this.$store.getters["colaboradores/data"].filter(colaborador => colaborador.statusCol !== 'OCULTO');
      return colaboladoresFlter;
    },
  },
  methods: {
    ...mapActions("colaboradores", ["getAllInfoClb"]),

    srcImg: function (img) {
      let src = `${urlpath.url()}/colaboradores-img/${img}`;
      return src;
    },
    querySM(name) {
      let a1 = name.split("/");
      return a1[3];
    },
    fbSM(name) {
      let a1 = name.split("/");
      return a1[3];
    },
  },
};
</script>
<style>
.header-c-c {
  width: 100%;
  height: 38.217vw;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/header2.png");
  margin-bottom: 24.155vw;
}
.collabo-item .collabo-img {
  width: 79.227vw;
  margin: auto;
  display: flex;
  border-radius: 100%;
  overflow: hidden;
}
.collabo-item .t {
  font-size: 5vw;
  line-height: 5vw;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 12.077vw 0 1.077vw;
}
.collabo-item .desc {
  width: 79.952vw;
  margin: auto;
  padding-bottom: 9.662vw;
}
.collabo-item .desc p {
  font-size: 2.7vw;
  line-height: 2.9vw;
  margin: 0;
  font-weight: 500;
  text-align: center;
}
  .collabo-item .sm-c {
    padding-bottom: 4vw;
  }
.collabo-item a.sm {
  font-size: 2.6vw;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  
}
.fb span {
  color: rgb(91, 91, 243);
}
.tw span {
  color: rgb(91, 200, 243);
}
.ig span {
  color: rgb(243, 91, 228);
}
.li span {
  color: rgb(8, 0, 255);
}
@media (min-width: 768px) {
  .header-c-c {
    width: 100%;
    height: 12.74vw;
    margin-bottom: 5.208vw;
  }
  .mosaic-c {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: auto;
  }
  .collabo-item {
    width: 19.948vw;
    padding: 0 4vw;
    margin-bottom: 5vw;
    border-bottom: .1vw solid rgb(125, 125, 125);
  }
  .collabo-item:nth-last-child(2),
  .collabo-item:last-child{
    border-bottom: .1vw solid transparent;
  }
  .collabo-item .collabo-img {
    width: 17.448vw;
  }
  .collabo-item .t {
    font-size: 1.1vw;
    line-height: 1.1vw;
    margin: 4.688vw 0 1.077vw;
  }
  .collabo-item .sm-c {
    padding-bottom: 1.4vw;
  }
  .collabo-item a.sm {
    font-size: 0.6vw;
  }
  .collabo-item .desc {
    width: 100%;
    padding-bottom: 4.662vw;
  }
  .collabo-item .desc p {
    font-size: 1vw;
    line-height: 1.2vw;
  }
}
</style>